import s from './FAQ.module.scss';

export default function FAQ() {
  return (
    <header className={s.header}>
      <div className={s.header_wrapper}>
        <div className={s.header_text}>
          <h1>Refund Policy</h1>
          <div className={s.question}>
            <h2>Can I receive a refund if I did not use StyleScan Studio?</h2>
            <p>
              No. When you pay for a subscription to StyleScan Studio, you are purchasing the right to access our
              platform for the duration of the subscription period. This access is granted regardless of the level of
              usage. Refunds are not provided for periods when the service is unused. If you encounter any issues or
              require assistance with the cancellation process, please contact our support team at{' '}
              <a href="mailto:info@stylescan.com">info@stylescan.com</a>.
            </p>
          </div>
          <div className={s.question}>
            <h2>Can I receive a refund if I uninstall my Shopify plugin?</h2>
            <p>
              No. Uninstalling the Shopify plugin does not automatically cancel your StyleScan subscription. It is your
              responsibility to manage your subscription with StyleScan by logging into your account and completing the
              cancellation process.
            </p>
          </div>
          <div className={s.question}>
            <h2>Can I receive a refund if I didn't cancel my subscription after the free trial ended?</h2>
            <p>
              No. It is your responsibility to manage your subscription with StyleScan by logging into your account and
              completing the cancellation process before the trial ends.
            </p>
          </div>
          <h1>FAQ</h1>
          <div className={s.question}>
            <p>
              Welcome to StyleScan! If you're new to our software, you might have questions about using the platform. To
              help you navigate the process, here are answers to some frequently asked questions:
            </p>
          </div>
          <div className={s.question}>
            <h2>How much does StyleScan Studio cost?</h2>
            <p>
              For pricing details, please visit our{' '}
              <a href="/pricing" target="_blank">
                pricing
              </a>{' '}
              page.
            </p>
          </div>
          <div className={s.question}>
            <h2>How can I learn to dress models?</h2>
            <p>
              All tutorials, along with tips and tricks, can be found on our{' '}
              <a href="https://youtube.com/@stylescan" target="_blank">
                YouTube
              </a>{' '}
              channel.
            </p>
          </div>
          <div className={s.question}>
            <h2>How do I save photos?</h2>
            <p>
              • For final images, choose .JPEG or .PNG formats
              <br />
              • To edit your images in the future, select .STUDIO file and save it to your device.
              <br />• Note: StyleScan Studio does not currently host images, but this feature is coming soon.
            </p>
          </div>
          <div className={s.question}>
            <h2>Am I locked into a contract?</h2>
            <p>No, you're billed monthly. Cancel any time to stop charges.</p>
          </div>
          <div className={s.question}>
            <h2>How do I cancel my subscription?</h2>
            <p>
              • Export any necessary materials from StyleScan.
              <br />
              • At the bottom of the tool panel inside StyleScan Studio, select 'Plans and Billing'.
              <br />
              • Select 'Cancel subscription'.
              <br />• Your account will automatically switch to StyleScan Freemium at the end of the billing period.
            </p>
          </div>
          <div className={s.question}>
            <h2>Can I receive a refund if I did not use StyleScan Studio?</h2>
            <p>
              No. When you pay for a subscription to StyleScan Studio, you are purchasing the right to access our
              platform for the duration of the subscription period. This access is granted regardless of the level of
              usage. Refunds are not provided for periods when the service is unused. If you encounter any issues or
              require assistance with the cancellation process, please contact our support team at{' '}
              <a href="mailto:info@stylescan.com">info@stylescan.com</a>.
            </p>
          </div>
          <div className={s.question}>
            <h2>Do I own the imagery I create in StyleScan Studio?</h2>
            <p>Yes, you own all the images you created using StyleScan. These images are considered “Your Content”.</p>
          </div>
          <div className={s.question}>
            <h2>Can I use images of StyleScan models in another software, such as Photoshop?</h2>
            <p>
              No. You can not use images of StyleScan models to create content in another software. In order for the
              model images to be considered “Your Content,” they have to be virtually dressed using StyleScan Studio.
              The original images of StyleScan models are the exclusive property of StyleScan. Any use of the model
              images outside of StyleScan software is strictly prohibited.
            </p>
          </div>
          <div className={s.question}>
            <h2>Can I use StyleScan Studio with Shopify?</h2>
            <p>
              Yes! To add StyleScan's plugin to your Shopify store, click{' '}
              <a href="https://apps.shopify.com/stylescan-studio" target="_blank">
                here
              </a>
              . For any other questions or concerns, please reach out to{' '}
              <a href="mailto:info@stylescan.com">info@stylescan.com</a>.
            </p>
          </div>
          <div className={s.question}>
            <h2>Does StyleScan own my designs?</h2>
            <p>No, you retain ownership of any imagery and content created using StyleScan.</p>
          </div>
          <div className={s.question}>
            <h2>Do I own images created in StyleScan after canceling my subscription?</h2>
            <p>Yes, you retain ownership of all the content you created, even post-cancellation.</p>
          </div>
          <div className={s.question}>
            <h2>Can I manage billing from a mobile device?</h2>
            <p>
              No. To manage billing, such as upgrade/cancel your plan, please log in from a desktop, select "Billing &
              Plans" at the bottom of the tool panel, and click on "Upgrade your Plan" or "Cancel your Plan".
            </p>
          </div>
        </div>
      </div>
    </header>
  );
}
