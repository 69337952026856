import s from './ContactUs.module.scss';
import {useState} from "react";
import toast, { Toaster } from "react-hot-toast";

export const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        position: '',
        company: '',
        revenue: '',
        website: '',
        followers: '',
        influencerWebsite: '',
        mediaCompany: '',
        mediaWebsite: '',
        subject: '',
        message: '',
    });

    // console.log(formData)


    const handleInputChange = event => {
        const { name, value } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        try {
            const res = await fetch('/handler.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams(formData),
            })
            console.log(res)
            if (res.ok === true) {
                toast.success('Thank You!')
                setFormData({
                    name: '',
                    email: '',
                    position: '',
                    company: '',
                    revenue: '',
                    website: '',
                    followers: '',
                    influencerWebsite: '',
                    mediaCompany: '',
                    mediaWebsite: '',
                    subject: '',
                    message: '',
                })
            } else {
                throw new Error()
            }

        } catch (error) {
            toast.error('Error occurred: ' + error)
        }
    };

    return (
        <section className={s.contactUs} id={'contact'}>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <div className={s.contactUs_wrapper}>
                <h2>Contact Us</h2>
                <p>
                    To find out more information, request a demo, or become a StyleScan Technology Partner, apply
                    below:
                </p>

                <form className={s.contactUs_form} onSubmit={handleSubmit}>
                    <input type="hidden" name="action" value="apply" required/>
                    <p>All fields are required.</p>
                    <input type="text" placeholder="Full Name" name="name" required value={formData.name}
                           onChange={handleInputChange}/>
                    <input type="email" placeholder="Email" name="email" required value={formData.email}
                           onChange={handleInputChange}/>
                    <select name="position" value={formData.position}
                            onChange={handleInputChange} required>
                        <option value="" disabled>Select your position</option>
                        <option value="company">Fashion Company</option>
                        <option value="company-executive">Fashion Executive / Consultant</option>
                        <option value="company-designer">Fashion Designer</option>
                        <option value="influencer">Fashion Model / Influencer</option>
                        <option value="investor">Investor</option>
                        <option value="media">Media Representative</option>
                        <option value="other">Other</option>
                    </select>
                    <div
                        id="company"
                        className={!formData.position.includes('company') && s.hidden}
                    >
                        <input name="company" type="text" placeholder="Company/Brand Name"
                               required={formData.position.includes('company')}
                               value={formData.company} onChange={handleInputChange}/>
                        <input name="revenue" type="text" placeholder="Annual Revenue"
                               required={formData.position.includes('company')}
                               value={formData.revenue} onChange={handleInputChange}/>
                        <input name="website" type="text" placeholder="Website"
                               required={formData.position.includes('company')}
                               value={formData.website} onChange={handleInputChange}/>
                    </div>
                    <div
                        id="influencer"
                        className={!formData.position.includes('influencer') && s.hidden}
                    >
                        <input name="followers" type="text" placeholder="Number of followers"
                               required={formData.position.includes('influencer')} value={formData.followers}
                               onChange={handleInputChange}/>
                        <input name="influencerWebsite" type="text"
                               required={formData.position.includes('influencer')}
                               placeholder="Blog / Website / Social Media Name"
                               value={formData.influencerWebsite} onChange={handleInputChange}/>
                    </div>
                    <div
                        id="media"
                        className={!formData.position.includes('media') && s.hidden}
                    >
                        <input name="mediaCompany" type="text" placeholder="Company Name"
                               required={formData.position.includes('media')} value={formData.mediaCompany}
                               onChange={handleInputChange}/>
                        <input name="mediaWebsite" type="text" placeholder="Website"
                               required={formData.position.includes('media')} value={formData.mediaWebsite}
                               onChange={handleInputChange}/>
                    </div>
                    <input name="subject" type="text" placeholder="Subject (Purpose of Inquiry)" required
                           value={formData.subject} onChange={handleInputChange}/>
                    <textarea name="message" rows="5" placeholder="Message" required
                              value={formData.message} onChange={handleInputChange}/>
                    <button className="submit">Submit</button>
                </form>
            </div>
        </section>
    );
}
