import s from './Section3.module.scss';

export const Section3 = () => {
    const strings = [
        {text: 'Create Ecomm\nImagery', link: 'https://stylescan.com/subscribe/apparel/'},
        {text: 'Generate Ghost\nMannequin Images', link: 'https://stylescan.com/subscribe/ghost'},
        {text: 'Display 3D Designs\nOn Real People', link: 'https://stylescan.com/subscribe/3D'},
        {text: 'Dress Models\nin Your Jewelry', link: 'https://stylescan.com/subscribe/jewelry'},
        {text: 'Reinvent\nLinesheets', link: 'https://stylescan.com/subscribe/apparel/'},
        {text: 'Virtually Style\nYour Clients', link: 'https://stylescan.com/subscribe/style-clients/'},
        {text: 'Virtually Style\nYour Photo', link: 'https://stylescan.com/subscribe/try-on/'},
    ];

    const circle = (text, link) => {
        function handleClick() {
            // window.open(link, '_blank');
            window.open(link, '_self');
        }

        return (
            <div className={s.circle_el} onClick={handleClick} key={text}>
                <p>{text}</p>
            </div>
        );
    };

    return (
        <section className={s.section3} id={'solutions'}>
            <div className={s.section3_wrapper}>
                <h2 data-aos="fade-down" data-aos-duration="1000">Find Your Solution</h2>
                <div className={s.circles_block} data-aos="fade-up" data-aos-duration="1000" >
                    {strings.map(string => circle(string.text, string.link))}
                </div>
            </div>
        </section>
    );
};
