import s from './PricingPage.module.scss';

export const PricingPage = () => {
    const plans = [
        {
            name: 'STARTER',
            url: 'basic',
            price: 'First 7 days for $1\n$29/month after',
            desc: 'Suitable for emerging designers, stylists, content creators, and anyone interested in trying StyleScan.',
            features: [
                'Monthly auto-pay by credit card',
                'Full access to the model database',
                'Upload your own models',
                'StyleScan photo editor',
                '40 image exports per month',
                'Image exports reset every month',
                'Shopify plug-in available',
                'Available on desktop and tablet',
                'Cancel anytime',
                // 'Trial is available to first-time users only',
            ],
        },
        {
            name: 'POWER USER',
            url: 'premium',
            price: '$99/month\nper user',
            desc: 'Suitable for manufacturers, online retailers, fashion brands\' social media managers, and similar.',
            features: [
                'Monthly auto-pay by credit card',
                'Full access to the model database',
                'Upload your own models',
                'StyleScan photo editor',
                '300 image exports per month',
                'Image exports reset every month',
                'Shopify plug-in available',
                'Available on desktop and tablet',
                'Cancel anytime',
            ],
        },
        {
            name: 'SUPER USER',
            url: 'super',
            price: '$199/month\nper user',
            desc: 'Suitable for designers, e-commerce platforms, and fashion brands with high SKU volumes and image creation needs.',
            features: [
                'Monthly auto-pay by credit card',
                'Full access to the model database',
                'Upload your own models',
                'StyleScan photo editor',
                '1000 image exports per month',
                'Image exports reset every month',
                'Shopify plug-in available',
                'Available on desktop and tablet',
                'Cancel anytime',
            ],
        },
        {
            name: 'ENTERPRISE',
            url: '',
            price: '$399/month\nper user',
            desc: 'Suitable for large enterprises with high SKU volumes and image creation needs.',
            features: [
                'Annual plans available',
                'Full access to the model database',
                'Upload your own models',
                'StyleScan photo editor',
                'Custom number of image exports',
                'Image exports carry over to the next month',
                'Custom features available to clients with 5+ user licenses',
                // 'Available on desktop and tablet',
            ],
        },
    ];

    return (
        <div className={s.pricingPage}>
            <section className={s.pricingPage_plansCards}>
                {plans.map(({ name, url, price, desc, features }) => (
                    <div className={s.planCard} key={name}>
                        <h1>{name}</h1>
                        <div>{price.split('\n').map((line) => <h4 key={line}>{line}</h4>)}</div>
                        <p>{desc}</p>
                        <ul>{features.map((feature) => <li key={feature}>{feature}</li>)}</ul>
                        <a
                            href={
                                name === 'ENTERPRISE'
                                    ? '/#contact'
                                    : `https://studio.stylescan.com/signup?${new URLSearchParams({
                                        plan: url,
                                        ref: document.referrer || '',
                                    })}`
                            }
                        >
                            {name === 'ENTERPRISE' ? 'Contact us' : 'Select Plan'}
                        </a>
                        {/* {name === 'STARTER' && <h5 className={s.disclaimer}>Trial is available to first-time users only *</h5>} */}
                    </div>
                ))}
            </section>
            <p className={s.pricingPage_p}>Are you a fashion student? You may qualify for unlimited free access! <a href="/#contact">Contact Us</a></p>
            <p style={{marginTop:'-25px'}}>* Trial is available to first-time users only.</p>
            <p style={{marginTop:'-25px'}}>* Uninstalling your Shopify plugin does not cancel your StyleScan subscription. Log in to your account to complete the cancellation.</p>
        </div>
    );
};
