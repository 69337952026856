import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import f_video from '../images/section_4.mp4';
import pages from './subscribePages.json';
import s from './Subscribe.module.scss';

export default function Subscribe() {
  const path = useLocation().pathname.split('/')[2];
  const { tagline, video, cards } = pages.find((p) => p.path === path);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 1000);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  let ref = '';
  ref += document.referrer || '';
  ref += window.location.search || '';

  return (
    <>
      <header className={s.header}>
        <div className={s.header_wrapper}>
          <div className={s.header_text}>
            <img
              src={require('../images/subscribe-pages/studio-logo.png')}
              alt="StyleScan Studio logo"
              className={s.header_img}
            />
            {!isMobile &&
              tagline.split('\n').map((text, i) => (
                <h3 key={i} className={s.h3}>
                  {text}
                </h3>
              ))}
            <a
              href={`https://studio.stylescan.com/signup?${new URLSearchParams({ plan: 'basic', ref })}`}
              target="_blank"
              className={s.btn}
            >
              Try Now
            </a>
            <p className={s.sub}>Currently available on desktop & tablet</p>
          </div>
          {path === 'fashion' ? (
            <video
              src={f_video}
              muted
              autoPlay
              playsInline
              loop
              className={s.f_video}
              style={{ marginTop: '-24px', marginBottom: '16px' }}
            />
          ) : (
            <iframe
              src={video}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              className={s.header_video}
            />
          )}
          {isMobile && (
            <div className={s.header_text}>
              {tagline.split('\n').map((text, i) => (
                <h3 className={s.h3}>{i ? text.toLowerCase() : text[0] + text.slice(1).toLowerCase()}</h3>
              ))}
            </div>
          )}
        </div>
      </header>
      <section className={s.section}>
        <div className={s.wrapper}>
          <h1 className={s.h1}>How it works</h1>
          <div className={s.card_container}>
            {cards.map(({ head, body }, i) => (
              <div key={i} className={s.card}>
                <img
                  src={require(`../images/subscribe-pages/${path}/${i + 1}.jpg`)}
                  alt="card thumbnail"
                  className={s.card_img}
                />
                {/* <div className={s.sep} /> */}
                <h3 className={s.h3}>{head}</h3>
                {/* <div className={s.sep} /> */}
                <p className={s.p}>{body}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
