import s from './Section4.module.scss';
import iframe_vid from '../../images/section_4.mp4';
import frame from '../../images/section4/frame.svg'

export const Section4 = () => {

    return (
        <section className={s.section4} id={'technology'}>
            <div className={s.section4_inFrame}>
                <video playsInline autoPlay muted loop className={s.section4_inFrame__vid}>
                    <source src={iframe_vid} type="video/mp4"/>
                </video>
                <img src={frame} alt="frame" className={s.section4_inFrame__frame}/>
            </div>
            <div className={s.section4_wrapper}>
                <div className={s.section4_content} data-aos="fade-left" data-aos-duration="1000">
                    <h2>Our Technology</h2>
                    <p>
                        In a matter of minutes, manufacturers and retailers of
                        all sizes can create visually appealing content without
                        any additional expenses, all while working remotely.<br className={s.show} />
                        <br className={s.show} />
                        With StyleScan, brands can fully control their visual identity, minimize
                        garment waste, and promote sustainability.<br className={s.show} />
                        {/* <br className={s.show} /> */}
                    </p>
                    <div className={s.separator} />
                    <p className={s.special}>
                        Are you a student? You may qualify for a free software license via StyleScan’s Educational Sponsorship. <br className={s.showMobile} />
                        <a href="https://stylescan.com/clients/student-factsheet.pdf" target="_blank">Click here</a> to learn more or <a href="#contact">contact us</a>.
                    </p>
                </div>
            </div>
        </section>
    );
};
