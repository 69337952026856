import {Main} from "../components/Main/Main";
import {Section2} from "../components/Section2/Section2";
import {Section3} from "../components/Section3/Section3";
import {Section4} from "../components/Section4/Section4";
import {Section5} from "../components/Section5/Section5";
import {Section6} from "../components/Section6/Section6";
import {Section7} from "../components/Section7/Section7";
import {ContactUs} from "../components/ContactUs/ContactUs";

import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import AOS from 'aos';


function Site() {
  const { hash } = useLocation();
  useEffect(() => {
    // hack for # url support
    setTimeout(() => {
      hash && document.querySelector(hash)?.scrollIntoView({ behavior: 'smooth' });
    }, hash === '#press' || hash === '#contact' ? 1000 : 0); // timing hack to "wait" for loading

    AOS.init();
  }, []);

  return (
    <>
      <Main/>
      <Section2/>
      <Section7/>
      <Section4/>
      <Section3/>
      <Section6/>
      <Section5/>
      <ContactUs/>
    </>
  );
}
export default Site;
