import { Outlet } from 'react-router-dom';
import { Navbar } from './components/Navbar/Nav';
import { Footer } from './components/Footer/Footer';

import s from './App.module.scss';

export default function App() {
  return (
    <div className={s.App}>
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
}
