import s from './Section2.module.scss';
import head_vid from '../../images/section2/section2_vid.mp4';
import img1 from '../../images/section2/img1.svg';
import img2 from '../../images/section2/img2.svg';
import img3 from '../../images/section2/img3.svg';

export const Section2 = () => {
    return (
        <section className={s.section2}>
            <video playsInline autoPlay muted loop className={s.section2_headVid}>
                <source src={head_vid} type="video/mp4"/>
            </video>
            <div className={s.section2_wrapper}>
                <div className={s.section2_content}>
                    <h2 data-aos="fade-left" data-aos-duration="500" >Digitally Dress Models</h2>
                    <p data-aos="fade-left" data-aos-duration="1000" >
                        With StyleScan, brands can generate high quality images of their
                        products on a wide range of models, engaging consumers <br/>
                        in a highly personalized shopping experience. StyleScan <br />
                        software works with both 2D and 3D images of <br />
                        apparel, accessories, jewelry, and footwear.
                    </p>
                    <div className={s.section2_content__images} data-aos="fade-left" data-aos-duration="1500" >
                        <img src={img1} alt=""/>
                        <img src={img2} alt=""/>
                        <img src={img3} alt=""/>
                    </div>
                    <h3 data-aos="fade-left" data-aos-duration="2000" >
                        Create product images using 500+ real <br/>
                        model photos from StyleScan database <br/>
                        or upload your own models
                    </h3>
                    <button className={s.section2_content_btn} data-aos="fade-left" data-aos-duration="2500">
                        <a href='https://studio.stylescan.com/signup' target="_blank">Try Now</a>
                    </button>
                </div>
            </div>

        </section>
    )
};