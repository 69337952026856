import s from './Main.module.scss';
import desktop from '../../images/reencodedDesk.mp4';
import mobile from '../../images/reencoded.mp4';
import circle1 from '../../images/main_section/1st_circle.svg'
import circle2 from '../../images/main_section/2nd_circle.svg'
import circle3 from '../../images/main_section/3rd_circle.svg'
import 'aos/dist/aos.css';

export const Main = () => {

    const circles = [
        {
            header: '168X',
            paragraph: 'SPEED TO MARKET',
        },
        {
            header: '98%',
            paragraph: 'COST SAVING',
        },
        {
            header: '100%',
            paragraph: 'SUSTAINABLE',
        },
    ];

    const circle = (circle_content) => {
        return (
            <div className={s.circle} key={circle_content.header}>
                <h3>{circle_content.header}</h3>
                <p>{circle_content.paragraph}</p>
            </div>
        )
    };

    return (
        <section className={s.main} id={'home'}>
            <video playsInline autoPlay muted loop className={s.main_videoDesktop}>
                <source src={desktop} type="video/mp4"/>
            </video>
             <video playsInline autoPlay muted loop className={s.main_videoMobile}>
                <source src={mobile} type="video/mp4"/>
            </video>
            <div className={s.main_wrapper}>
                <div className={s.main_content} data-aos="fade-right" data-aos-duration="500">
                    <h1 className={s.main_content__header}>
                        AI-Powered <br/>
                        Productivity Software <br/>
                        For Fashion Businesses
                    </h1>
                    <p className={s.main_content__p} data-aos="fade-right" data-aos-duration="500">
                        StyleScan revolutionizes on-model image creation, <br/>
                        accelerating speed-to-market and <br/>
                        drastically reducing costs.
                    </p>
                    <div className={s.main_content__images} data-aos="fade-up" data-aos-duration="1000" data-aos-offset="10">
                        {circles.map(c => circle(c))}
                        {/*<img src={circle1} alt=""/>*/}
                        {/*<img src={circle2} alt=""/>*/}
                        {/*<img src={circle3} alt=""/>*/}
                    </div>
                </div>
            </div>

        </section>
    )
};