import { useLocation } from 'react-router-dom';
import header from '../images/subscribe-pages-test/header.png';
import right from '../images/subscribe-pages-test/right.png';
import down from '../images/subscribe-pages-test/down.png';
import solution1 from '../images/subscribe-pages-test/solution-1.png';
import solution2 from '../images/subscribe-pages-test/solution-2.png';
import f_video from '../images/section_4.mp4';
import pages from './subscribePagesTest.json';
import s from './SubscribeTest.module.scss';

export default function SubscribeTest() {
  const path = useLocation().pathname.split('/')[2];
  const { title, desc, video, cards } = pages.find((p) => p.path === path);

  let ref = '';
  ref += document.referrer || '';
  ref += window.location.search || '';

  return (
    <>
      <header className={s.header}>
        <div className={s.header_wrapper}>
          <div className={s.header_main}>
            <div className={s.header_text}>
              {title.split('\n').map((line) => (
                <h1>{line}</h1>
              ))}
              <p>{desc}</p>
              <a
                href={`https://studio.stylescan.com/signup?${new URLSearchParams({ plan: 'basic', ref })}`}
                target="_blank"
                className={s.btn}
              >
                Start your free trial!
              </a>
            </div>
            {path === 'fashion' ? (
              <video
                src={f_video}
                muted
                autoPlay
                playsInline
                loop
                className={s.f_video}
                style={{ marginTop: '-24px', marginBottom: '16px' }}
              />
            ) : (
              <iframe
                src={video}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                className={s.header_video}
              />
            )}
          </div>
          <img src={header} className={s.header_img} />
        </div>
      </header>

      <section className={s.section2}>
        <div className={s.wrapper}>
          <h1>AI-POWERED PRODUCTIVITY SOFTWARE FOR</h1>
          <h1>DESIGN | MERCHANDISING | MARKETING</h1>

          <div className={s.sep} />

          <div className={s.card_container_d}>
            {/* top row */}
            <img
              src={require(`../images/subscribe-pages-test/${path}-${1}.png`)}
              alt="thumbnail"
              className={s.card_img}
            />
            <img src={right} />
            <img
              src={require(`../images/subscribe-pages-test/${path}-${2}.png`)}
              alt="thumbnail"
              className={s.card_img}
            />
            <img src={right} />
            <img
              src={require(`../images/subscribe-pages-test/${path}-${3}.png`)}
              alt="thumbnail"
              className={s.card_img}
            />
            {/* mid row */}
            <h3>{cards[0].head}</h3>
            <br />
            <h3>{cards[1].head}</h3>
            <br />
            <h3>{cards[2].head}</h3>
            {/* bot row */}
            <p>{cards[0].body}</p>
            <br />
            <p>{cards[1].body}</p>
            <br />
            <p>{cards[2].body}</p>
          </div>
          <div className={s.card_container_m}>
            {cards.map(({ head, body }, i) => (
              <div>
                <img
                  src={require(`../images/subscribe-pages-test/${path}-${i + 1}.png`)}
                  alt="thumbnail"
                  className={s.card_img}
                />
                <h3>{head}</h3>
                <p>{body}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className={s.section3}>
        <div className={s.wrapper}>
          <div className={s.botHeader}>
            <h1>FIND YOUR SOLUTION</h1>
            <img src={down} />
          </div>
          <div className={s.botImgs}>
            <img src={solution1} />
            <img src={solution2} />
          </div>
        </div>
      </section>
    </>
  );
}
