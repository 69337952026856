import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import s from './Tutorials.module.scss';

export default function Tutorials() {
  const [frames, setFrames] = useState([]);
  const [params, setParams] = useSearchParams();
  const [mainFrame, setMainFrame] = useState(params.get('id') || 0);
  const [mobile, setMobile] = useState(window.matchMedia('(max-width: 640px)').matches);

  const id = 'PLJ1GfP7aPQTw_effOH-Wdv7tCVFWsLgjy';
  const searchParams = new URLSearchParams({
    part: 'snippet',
    playlistId: id,
    maxResults: 50,
    key: 'AIzaSyBcpQ8Aj-yoN5IjQX9imu3SmNCEjhwU0MQ', // what do be a privacy
  });
  useEffect(() => {
    fetch(`https://www.googleapis.com/youtube/v3/playlistItems?${searchParams}`)
      .then((res) => res.json())
      .then(({ items }) => setFrames(items.filter(({ snippet }) => snippet.thumbnails?.maxres?.url)));

    const handleResize = () => setMobile(window.matchMedia('(max-width: 640px)').matches);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <header className={s.header}>TUTORIALS</header>
      <main className={s.main}>
        {!mobile && (
          <div className={s.player}>
            <iframe src={`https://youtube.com/embed?list=${id}&index=${mainFrame}`} />
          </div>
        )}
        <section className={s.videos}>
          {frames.map(({ snippet }, i) =>
            mobile ? (
              <div key={`${i}-mobile`}>
                <iframe src={`https://youtube.com/embed?list=${id}&index=${i + 1}`} />
              </div>
            ) : (
              <div
              key={`${i}-desktop`}
              onClick={() => {
                setMainFrame(i + 1);
                setParams({ id: i + 1 });
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
              >
                <img src={snippet.thumbnails.maxres.url} alt={snippet.title} />
                <h2>{snippet.title}</h2>
              </div>
            ),
          )}
        </section>
      </main>
    </>
  );
}
