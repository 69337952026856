import { useEffect, useState } from 'react';
import s from './Section7.module.scss';
import video from '../../images/section7/section7video.mp4';
import thumb from '../../images/section7/section7thumb.jpg';
import thumb2 from '../../images/section7/section7thumbMobile.jpg';

export const Section7 = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 1000);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
        <section className={s.section7}>
            <div>
                <video src={video} controls playsinline poster={isMobile ? thumb2 : thumb} />
            </div>
        </section>
    );
};
