import styleScanLogo from '../../images/whiteLogo.png';
import s from './Nav.module.scss';
import {useEffect, useState} from "react";

export const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const onOpenBurgerHandler = () => {
        setIsOpen(!isOpen)
    };

    useEffect(() => {
        if (window.innerWidth < 601) {
            window.document.body.style.overflow = `${isOpen ? 'hidden' : 'auto'}`;
        }
    }, [isOpen]);

    return (
        <div className={`${s.nav} ${s.box} ${isOpen && s.navbar_active}`}>
            <div className={s.nav_wrapper}>
                <img src={styleScanLogo} alt="StyleScan logo" className={s.nav_logo} onClick={() => window.open('/', '_self')}/>

                <button className={`${s.btn} ${s.burger} ${isOpen && s.burger_opened}`}
                        onClick={onOpenBurgerHandler}></button>

                <div className={s.box}>
                    <div className={s.nav_links}>
                        <ul className={s.nav_links__list}>
                            <li className={s.nav_links__list___link}>
                                <a href="/#solutions" onClick={onOpenBurgerHandler}>Solutions</a>
                            </li>
                            <li className={s.nav_links__list___link}>
                                <a href="/#technology" onClick={onOpenBurgerHandler}>Technology</a>
                            </li>
                            <li className={s.nav_links__list___link}>
                                <a href="/pricing" target="_blank" onClick={onOpenBurgerHandler}>Pricing</a>
                            </li>
                            <li className={s.nav_links__list___link}>
                                <a href="https://studio.stylescan.com/" target="_blank" onClick={onOpenBurgerHandler}>Login</a>
                            </li>
                            <li className={s.nav_links__list___link}>
                                <a href="/#contact" onClick={onOpenBurgerHandler}>Contact</a>
                            </li>
                            <li className={s.nav_links__list___link}>
                                <a href="/faq" onClick={onOpenBurgerHandler}>FAQ</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <button className={s.nav_links__btn}>
                    <a
                        href={`https://studio.stylescan.com/signup?${new URLSearchParams({ plan: 'basic', ref: document.referrer || '' })}`}
                        target="_blank"
                    >
                        Try Now
                    </a>
                    <a
                        href={`https://studio.stylescan.com/signup?${new URLSearchParams({ plan: 'basic', ref: document.referrer || '' })}`}
                        target="_blank"
                    >
                        Try Now
                    </a>
                </button>
            </div>
        </div>
    );
};
