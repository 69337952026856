import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Navbar } from '../components/Navbar/Nav';
import articles from './blogArticles.json';
import s from './Blog.module.scss';

export default function Blog() {
  const [params, setParams] = useSearchParams();
  const [selected, setSelected] = useState(!!params.get('id') || false);
  const [curr, setCurr] = useState(parseInt(params.get('id')) - 1);

  return (
    <>
      <header className={s.header}>
        <Navbar />
      </header>
      <main className={s.main}>
        {!selected && <h1>BLOG</h1>}
        {selected && (
          <>
            <svg
              shapeRendering="geometricPrecision"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              fill="none"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className={s.svg}
              onClick={() => {
                setSelected(false);
                params.delete('id');
                setParams(params);
              }}
            >
              <path d="M19 12H5" />
              <path d="M12 19l-7-7 7-7" />
            </svg>
            <h1>{articles[curr].name}</h1>

            <section className={s.article}>
              <img src={require(`../images/blog/${curr + 1}.${curr ? 'jpg' : 'gif'}`)} alt={`${articles[curr].name} thumbnail`} />
              <span>
                <p>{articles[curr].author}</p>
                <p>{articles[curr].date}</p>
              </span>
              <h3>
                {articles[curr].body.split('<img>').map((text, i, arr) => (
                  <>
                    {text.split('\n').map((moretext) => (
                      <>
                        {moretext}
                        <br />
                      </>
                    ))}
                    {i < arr.length - 1 && (
                      <img src={require(`../images/blog/${curr + 1}-${i}.${curr ? 'jpg' : 'gif'}`)} />
                    )}
                  </>
                ))}
              </h3>
            </section>

            <h2 className={s.more}>Read more</h2>
          </>
        )}
        <section className={s.articles}>
          {articles.map(({ name, author, body, date }, i) => {
            if (selected && i > 3) return;

            return (
              <div
                key={i}
                onClick={() => {
                  setCurr(i);
                  setSelected(true);
                  setParams({ id: i + 1 });
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
              >
                <img src={require(`../images/blog/${i + 1}.${i ? 'jpg' : 'gif'}`)} alt={`${name} thumbnail`} />
                <h2>{name}</h2>
                <h3>{body.slice(0, 200) + (body.length >= 200 ? '...' : '')}</h3>
                <p>{author}</p>
                <p>{date}</p>
              </div>
            );
          })}
        </section>
      </main>
    </>
  );
}
