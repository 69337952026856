import s from './Footer.module.scss';
import styleScanLogo from '../../images/whiteLogo.png';

export const Footer = () => {

    return (
        <section className={s.footer}>
            <div className={s.footer_wrapper}>
                <img src={styleScanLogo} alt="styleScan logo"/>
                <div className={s.boxes}>
                    <div className={s.footer_contactUs}>
                        <h3>Contact Us</h3>
                        <a href="https://www.google.com/maps/place/Los+Angeles,+CA/">
                        <span className={s.footer_contactUs_svg}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                 strokeLinejoin="round">
                                <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                                <circle cx="12" cy="10" r="3"></circle>
                            </svg>
                        </span>
                            Los Angeles, CA 90049
                        </a>
                        <a href="mailto:info@stylescan.com">
                        <span className={s.footer_contactUs_svg}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                 strokeLinejoin="round">
                                <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z">
                                </path>
                                <polyline points="22,6 12,13 2,6"></polyline>
                            </svg>
                        </span>
                            info@stylescan.com
                        </a>
                    </div>
                    <div className={s.footer_links}>
                        <a href="https://stylescan.com/about.html" target="_blank">Team</a>
                        <a href="/blog" target='_blank'>Blog</a>
                        <a href="https://stylescan.com/faq" target="_blank">FAQ</a>
                        <a href="https://stylescan.com/shop-privacy.html" target="_blank">Privacy</a>
                        <a href="https://stylescan.com/clients/student-factsheet.pdf" target="_blank">Student Fact Sheet</a>
                    </div>
                </div>
            </div>
        </section>
    );
};
