import s from './Section5.module.scss';
import shopify from '../../images/clients&partners/Shopify-logo.png';
import appStore from '../../images/clients&partners/App Store.png';
import look from '../../images/clients&partners/Look logo.png';
import allaBerman from '../../images/clients&partners/Alla Berman.png';
import deMarquet from '../../images/clients&partners/De Marquet.png';
import academyOfArt from '../../images/clients&partners/academyOfArt.png';
import loulou from '../../images/clients&partners/loulou.png';
import theREMODA from '../../images/clients&partners/theREMODA.png';
import stillTrust from '../../images/clients&partners/Stil Trust.png';
import fit from '../../images/clients&partners/fit.png';
import msu from '../../images/clients&partners/msu.png';
import rei from '../../images/clients&partners/reigiraldo.png';

export const Section5 = () => {
    const clients = [
        theREMODA, academyOfArt, allaBerman,
        stillTrust, msu, loulou,
        deMarquet, fit, rei,
    ];

    return (
        <section className={s.section5}>
            <div className={s.section5_wrapper}>
                <div className={s.section5_clients} data-aos="fade-left" data-aos-duration="1000">
                    <h2>Over 1000 customers, including:</h2>
                    <div className={s.section5_clients__images}>
                        {clients.map(client => <img key={client} src={client} alt={`${client} logo`} className={s.gridImg}/>)}
                    </div>
                </div>
                <div className={s.section5_partners} data-aos="fade-right" data-aos-duration="1000" >
                    <h2>Integration Partners</h2>
                    <div className={s.section5_partners__images}>
                        <a href="https://apps.shopify.com/virtual-styiling-studio" target="_blank"><img src={shopify} alt="shopify"/></a>
                        <a href="/" target="_blank"><img src={appStore} alt="app store"/></a>
                        <a href="https://look-platform.com/" target="_blank"><img src={look} alt="look"/></a>
                    </div>
                    <p>StyleScan is a seamless add-on to any PLM or other web-based platform. To discuss
                        integration, <a href="#contact">contact us</a>.</p>
                </div>
            </div>
        </section>
    );
};
