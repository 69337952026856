import s from './Section6.module.scss';
import forbes from '../../images/media_slider/Forbes.png';
import glossy from '../../images/media_slider/Glossy.png';
import laBusiness from '../../images/media_slider/LA Business Journal.png';
import laTimes from '../../images/media_slider/LA Times.png';
import marieClaire from '../../images/media_slider/Marie Claire.png';
import wired from '../../images/media_slider/Wired.png';
import yahoo from '../../images/media_slider/Yahoo.png';

import {Swiper, SwiperSlide} from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import {Pagination, Autoplay} from "swiper";


export const Section6 = () => {
    const media = [
        {
            img: forbes,
            link: 'https://www.forbes.com/sites/stephanrabimov/2021/02/04/stylescan-is-disrupting-fashion-industry-with-ai'
        },
        {
            img: glossy,
            link: 'https://www.glossy.co/fashion/digital-models-are-coming-for-resale-and-vintage'
        },
        {
            img: laBusiness,
            link: 'https://labusinessjournal.com/technology/special-report-zero-waste-fashion'
        },
        {
            img: laTimes,
            link: 'https://www.latimes.com/b2b/inspirationalwomen/larissa-posner'
        },
        {
            img: marieClaire,
            link: 'https://marieclaire.ua/uk/lifestyle/larissa-posner-kak-ee-kompaniya-stylescan-prevrashhaet-virtualnuyu-primerku-odezhdy-v-realnost'
        },
        {
            img: wired,
            link: 'https://www.wired.co.uk/article/ai-personalised-shopping'
        },
        {
            img: yahoo,
            link: 'https://www.businesswire.com/news/home/20220630005156/en/StyleScan-Raises-1M-to-Expand-Its-Virtual-Dressing-Technology'
        },
    ];

    return (
        <section className={s.section6} id="press">
            {/*<img src={back} alt="" className={s.back}/>*/}
            <div className={s.section6_wrapper}>
                <h2>In The Press</h2>
                <div className={s.section6_slider}>
                    <Swiper
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Pagination, Autoplay]}
                        className={s.mySwiper}
                        // centeredSlides={true}
                        // centeredSlidesBounds={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false
                        }}
                    >
                        {media.map(m => <SwiperSlide key={m.link}>
                            <a href={m.link} target={'_blank'}>
                                <img src={m.img} alt={`${m.img} logo`}/>
                            </a>
                        </SwiperSlide>)}
                    </Swiper>
                </div>
            </div>
        </section>
    );
};
