import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.scss';
import App from './App';
import Site from './pages/Site';
import FAQ from './pages/FAQ';
import Blog from './pages/Blog';
import Subscribe from './pages/Subscribe';
import SubscribeTest from './pages/SubscribeTest';
import Tutorials from './pages/Tutorials';
import reportWebVitals from './reportWebVitals';
import subPages from './pages/subscribePages.json';
import subPagesTest from './pages/subscribePagesTest.json';
import { PricingPage } from './pages/PricingPage/PricingPage';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Site />} />
          <Route path="/faq" element={<FAQ />} />
          {subPages.map(({ path }, i) => (
            <Route key={i} path={`/subscribe/${path}`} element={<Subscribe />} />
          ))}
          {subPagesTest.map(({ path }, i) => (
            <Route key={i} path={`/subscribe-test/${path}`} element={<SubscribeTest />} />
          ))}
        </Route>
        <Route path="/blog" element={<Blog />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/studio/tutorials" element={<Tutorials />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
